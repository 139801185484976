import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import MakeStyles from '@bit/mui-org.material-ui.internal.make-styles'
import Grid from '@bit/mui-org.material-ui.grid'
import CardContent from '@bit/mui-org.material-ui.card-content'
import Card from '@bit/mui-org.material-ui.card'
import CardHeader from '@bit/mui-org.material-ui.card-header'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/pro-light-svg-icons'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`

function AuditedPoolChart() {
  const [isLoading, setLoading] = useState(true)
  const [chartData, setchartData] = useState({})

  const last12MonthsOfData = chartData?.totalKwhKwpMonthlyTotals?.slice(
    chartData.totalKwhKwpMonthlyTotals.length - 12
  )

  let period = []
  let kWh = []
  let kWp = []

  last12MonthsOfData?.map(item => {
    period.push(item.period)
    kWh.push(item.kWh)
    kWp.push(item.kWp)
  })

  let kWhmax
  let kWpmax

  if (kWh !== undefined) {
    kWhmax = Math.max(...kWh)
  }
  if (kWp !== undefined) {
    kWpmax = Math.max(...kWp)
  }

  useEffect(() => {
    API.get('stats', '/plants/stats').then(response => {
      setchartData(response)
      setLoading(false)
    })
  }, [])

  const data = {
    labels: period,
    fill: 'start',
    datasets: [
      {
        label: 'Total kWh Exported',
        order: 2,
        data: kWh,
        type: 'bar',
        backgroundColor: 'rgba(251,177,22.1)',
        borderColor: 'rgba(251,177,22,1)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: 'rgb(0,123,255)',
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3,
        yAxisID: 'y',
      },
      {
        type: 'line',
        label: '1 kWp Production',
        fill: false,
        order: 1,
        data: kWp,
        backgroundColor: 'rgba(0,123,255,0.1)',
        borderColor: 'rgba(0,123,255,1)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: 'rgb(0,123,255)',
        pointBorderColor: 'rgba(0, 123, 255)',
        pointStyle: 'rectRot',
        pointRadius: 5,
        borderWidth: 1.5,
        pointHoverRadius: 3,
        yAxisID: 'y1',
      },
    ],
  }
  const useStyles = MakeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    title: {
      fontSize: 28,
      color: '#1957a6',
    },
  }))

  const classes = useStyles()

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: true,
    },
    stacked: false,
    legend: {
      display: true,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return numeral(tooltipItem.yLabel).format('0,0') + ' kWh'
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: kWhmax + 100000,
        display: true,
        position: 'left',
        id: 'left-axis',
        title: {
          display: true,
          text: 'Total kWh Exported',
        },
      },
      y1: {
        min: 0,
        max: kWpmax + 40,
        type: 'linear',
        display: true,
        position: 'right',
        id: 'right-axis',
        title: {
          display: true,
          text: '1 kWp Production',
        },
      },
      x: {
        id: 'bottom-1',
        gridLines: {
          color: 'transparent',
        },
      },
    },
  }

  return (
    <>
      {isLoading ? (
        'loading...'
      ) : (
        <Grid
          container
          spacing={3}
          style={{ marginBottom: 20 }}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} lg={12}>
            <Card>
              <CardHeader
                avatar={
                  <FontAwesomeIcon
                    icon={faChartBar}
                    style={{ fontSize: 36, color: 'Dodgerblue' }}
                  />
                }
                title="Audited Solar Pool for the previous 12 months"
                classes={{
                  title: classes.title,
                }}
              />
              <CardContent>
                <ChartWrapper>
                  <Bar data={data} options={options} />
                </ChartWrapper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AuditedPoolChart
