import React from 'react'
import styled from 'styled-components'
import MakeStyles from '@bit/mui-org.material-ui.internal.make-styles'
import Grid from '@bit/mui-org.material-ui.grid'
import CardContent from '@bit/mui-org.material-ui.card-content'
import Card from '@bit/mui-org.material-ui.card'
import CardHeader from '@bit/mui-org.material-ui.card-header'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/pro-light-svg-icons'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`

function PlatformChart() {
  const useStyles = MakeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    title: {
      fontSize: 28,
      color: '#1957a6',
    },
  }))

  const classes = useStyles()
  const labels = ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023']
  const data = {
    labels: labels,
    fill: 'start',
    datasets: [
      {
        label: '2016-2023',
        data: [225800, 983579, 1721947, 2483246, 3685181, 5102781, 8169996, 12675545],
        backgroundColor: 'rgba(0,123,255,0.1)',
        borderColor: 'rgba(0,123,255,1)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: 'rgb(0,123,255)',
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3,
      },
    ],
  }

  const options = {
    maintainAspectRatio: true,
    legend: {
      display: true,
    },
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex
            const startYear = labels[0]
            const endYear = context[0].label.split('-')[1]
            const value = context.raw
            if (index === 0) {
              return `${startYear}: ${numeral(value).format('0,0')} kWh`
            } else {
              return `${startYear}-${endYear}: ${numeral(value).format('0,0')} kWh`
            }
          },
          title: function (context) {
            return context[0].label.split('-')[1]
          },
        },
      },
    scales: {
      y: {
        ticks: {
          callback: function (label, index, labels) {
            return numeral(label).format('0,0') + ' kWh'
          },
        },
      },
      x: {
        stacked: false,
        gridLines: {
          color: 'transparent',
        },
      },
    },
  }

  return (
    <Grid
      container
      spacing={3}
      style={{ marginBottom: 20 }}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} lg={12}>
        <Card>
          <CardHeader
            avatar={
              <FontAwesomeIcon
                icon={faChartBar}
                style={{ fontSize: 36, color: 'Dodgerblue' }}
              />
            }
            title="Cumulative Energy Sold to the Grid"
            classes={{
              title: classes.title,
            }}
          />
          <CardContent>
            <ChartWrapper>
              <Bar data={data} options={options} />
            </ChartWrapper>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PlatformChart
