import React, { useEffect, useState } from 'react'
import MakeStyles from '@bit/mui-org.material-ui.internal.make-styles'
import PlatformChart from '../components/Charts/PlatformChart'
import AuditedPoolChart from '../components/Charts/AuditedPoolChart'
import Layout from '../components/layout'
import Grid from '@bit/mui-org.material-ui.grid'
import Typography from '@bit/mui-org.material-ui.typography'
import Paper from '@bit/mui-org.material-ui.paper'
import List from '@bit/mui-org.material-ui.list'
import ListItem from '@bit/mui-org.material-ui.list-item'
import Divider from '@bit/mui-org.material-ui.divider'
import ListItemText from '@bit/mui-org.material-ui.list-item-text'
import ListItemAvatar from '@bit/mui-org.material-ui.list-item-avatar'
import Avatar from '@bit/mui-org.material-ui.avatar'
import solarPoolPerformance3 from '../images/solar-pool-performance3.webp'
import { Amplify, API } from 'aws-amplify'
import awsConfig from '../config/awsConfig'
import amplifyConfig from '../config/amplifyConfig'
import { Helmet } from 'react-helmet'

Amplify.configure(amplifyConfig)
API.configure(awsConfig)

const useStyles = MakeStyles({
  root: {
    fontWeight: 'bold',
    width: '100%',
    maxWidth: '51ch',
  },
  paper: {
    padding: 30,
    textAlign: 'center',
    marginTop: 40,
  },
  titleLeft: {
    display: 'block',
    align: 'center',
    width: 100,
  },
})

export default function Performance() {
  const classes = useStyles()
  const [data, setData] = useState({})

  useEffect(() => {
    API.get('stats', '/plants/stats').then(response => {
      setData(response)
    })
  }, [])

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cloud Solar Barbados - Start earning from the sun</title>
      </Helmet>
      <div className="container">
        <Grid
          container
          alignItems="flex-start"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
            <Paper className={classes.paper} elevation={3}>
              <h2 style={{ color: '#1957a6' }}>SOLAR POOL ENERGY</h2>

              <h3 style={{ textAlign: 'left' }}>Monthly Production Rate</h3>
              <p>
                For the month of {data.date}, our solar panels generated{' '}
                {data.kWhkWpLatestPeriod} kWh per kWp installed and our solar
                pool sold {data.totalKwhLatestPeriodFormatted} kWh of energy to
                the grid as per our audited data, with a total of{' '}
                {data.totalPlantCapacity} kWp of installed solar capacity!
              </p>
              <AuditedPoolChart />
              <h4>How to read the chart:</h4>
              <p>
                <strong>Total kWh sales</strong> are all the sales of solar
                being exported to the national grid by our solar pool. As we add
                new installations, the total solar generated will increase
                further.
                <br></br>
                <br></br>
                <strong>Specific kWh Production</strong> is the gross kilowatt
                hours directly exported by 1 kWp of installed solar panels (1
                solar panel = 0.26 kWp). Our software uses this average across
                our systems to distribute the solar energy sold by our clients'
                Digital Solar Panels to the grid.
              </p>
              <h3 style={{ textAlign: 'left' }}>Cumulative Production Rate</h3>
              <p>
                CloudSolar has been operating its solar pool for nearly{' '}
                <strong>7 years</strong>, we have been one of the early adopters
                of this technology and believe in its future position as the
                number 1 energy source in Barbados. As can be seen by the chart,
                we have been producing solar energy month by month, year by
                year, with minimal surprise or variability in our expectations.
                All of our installations have been performing to specification
                and we are earning money from the sun!
              </p>
              <img
                src={solarPoolPerformance3}
                alt="Cloud Solar Pool Performance"
              />
              <h4>How to read the chart:</h4>
              <p>
                <strong>Cumulative kWh sales</strong> are the running totals of
                all the sales of solar being exported to the national grid by
                our solar pool. As we add new installations, the total solar
                generated will increase further.
                <br></br>
                <br></br>
                <strong>Cumulative Specific kWh Production</strong> is the
                cumulative gross kilowatt hours directly exported by 1 kWp of
                installed solar panels (1 solar panel = 0.26 kWp). Our software
                uses this average across our systems to distribute the solar
                energy sold by our clients' Digital Solar Panels to the grid.
              </p>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className="container">
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Paper className={classes.paper} elevation={3}>
            <h2 style={{ color: '#1957a6', marginBottom: 60 }}>
              SOLAR ASSETS PORTFOLIO
            </h2>
            <Grid
              container
              item
              xs={12}
              sm={12}
              xl={12}
              lg={12}
              md={12}
              spacing={3}
            >
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <h3 style={{ color: '#1957a6', marginBottom: 60 }}>
                  Our Digital Solar Pool
                </h3>
                <List className={classes.root}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="O">O</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Ocean Hotels Corporate Centre 93.6 kWp - 208 panels (450 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since July 2023
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                    <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="G">G</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Breeders Upgrade 452.25 kWp - 1005 panels (450 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since June 2023
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="G">G</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="J+R Farms 567 kWp - 1260 panels (450 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since April 2023
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="G">G</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Balls Plant Upgrade 129.6 kWp - 288 panels (450 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since April 2023
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="G">G</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="ZRS Upgrade 447.3 kWp - 994 panels (450 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since January 2023
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="G">G</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Guinea 1,280 kWp - 3200 panels (400 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since January 2022
                          </Typography>
                          {' — Operation Date: December 21, 2021.'}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="M">M</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Monterey 400.5 kWp - 1335 panels (300 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since March 2020
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Z">Z</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="ZRS Farms 73.44 kWp - 257 panels (110 panels - 260 Watts & 147 panels - 305 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since May 2018
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="O">O</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Oldbury 96.46 kWp - 371 panels (260 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since February 2017
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="B">B</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Breeders 85.28 kWp - 328 panels (260 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since February 2017
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="B">B</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Bright Hall 82.16 kWp - 316 panels (260 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since January 2017
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="R">R</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Ridge 65.52 kWp - 252 panels (260 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since April 2016
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="B">B</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Balls Plantation 172.64 kWp - 664 panels (260 Watts)"
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            Online since May 2016
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <h3 style={{ color: '#1957a6', marginBottom: 60 }}>
                  Our Future Solar Panel Installs
                </h3>
                <List className={classes.root}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="N">N</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Check back soon for new projects"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
      <div className="container">
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
            <Paper className={classes.paper} elevation={3}>
              <h2 style={{ color: '#1957a6', marginBottom: 60 }}>
                HISTORICAL ENERGY SALES{' '}
              </h2>
              <PlatformChart chartData={data.totalKwhKwpMonthlyTotals} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}
